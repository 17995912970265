import "./App.css";

import React from "react";
import CreateTypes from "./components/CreateTypes";
import CreateCodes from "./components/CreateCodes";

function App() {
  return (
    <div className="App">
      <CreateTypes />
      <CreateCodes />
    </div>
  );
}

export default App;
