import { initializeApp } from 'firebase/app';
import {
	getFirestore,
	collection,
	getDocs,
	doc,
	setDoc,
	orderBy,
	query,
	where,
	getDoc,
	deleteDoc,
} from 'firebase/firestore';

const firebaseConfig = {
	apiKey: 'AIzaSyD-q85x_5M0PLXalipdhVs5lX4WeZvbPmk',
	authDomain: 'mariaborgesaccessory.firebaseapp.com',
	projectId: 'mariaborgesaccessory',
	storageBucket: 'mariaborgesaccessory.appspot.com',
	messagingSenderId: '752965758845',
	appId: '1:752965758845:web:a4b8c78acc536aaaf76614',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export async function get(database) {
	const databaseCol = collection(db, database);
	const q = query(databaseCol, orderBy('code', 'asc'));
	const databaseSnapshot = await getDocs(q);
	const databaseList = databaseSnapshot.docs.map(doc => doc.data());
	return databaseList;
}

export async function getOneByName(database, name) {
	const search = query(collection(db, database), where('name', '==', name));

	const searchSnapshot = await getDocs(search);
	const searchList = searchSnapshot.docs.map(doc => doc.data());

	return searchList;
}

export async function getOneById(database, id) {
	const search = doc(db, database, id);

	const searchSnapshot = await getDoc(search);

	const searchList = searchSnapshot.data();

	return searchList;
}

export async function set(database, data) {
	const databaseCol = doc(db, database, data.code);
	await setDoc(databaseCol, data);
}

export async function removeDoc(database, data) {
	await deleteDoc(doc(db, database, data.code));
}
