import { getOneById, getOneByName, set, removeDoc } from './firebase';

export const setCode = async data => {
	let type = await getOneByName('types', data.type).then(res => {
		return res[0];
	});

	const newQnt = type.Quantity + 1;

	const number = newQnt.toString().padStart(3, '0');
	const code = type.code + number;
	data = { ...data, code };

	// ATT TYPE
	set('types', { ...type, Quantity: newQnt })
		.then(res => {
			return res;
		})
		.catch(err => {
			console.log({ err });
		});

	//ADD DOC IN CODE
	set('codes', data)
		.then(res => {})
		.catch(err => {
			console.log({ err });
		});

	let codeGerado = await getOneById('codes', code);

	return codeGerado;
};

export const removeOneCode = async data => {
	let type = await getOneByName('types', data.type).then(res => {
		return res[0];
	});

	const newQnt = type.Quantity - 1;

	// ATT TYPE
	set('types', { ...type, Quantity: newQnt })
		.then(res => {
			return res;
		})
		.catch(err => {
			console.log({ err });
		});

	//remove DOC IN CODE
	removeDoc('codes', data)
		.then(res => {})
		.catch(err => {
			console.log({ err });
		});

	return null;
};
