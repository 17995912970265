import './Geral.css';
import './Components.css';
import React, { useEffect, useState } from 'react';
import { get } from '../api/firebase';
import { removeOneCode, setCode } from '../api/codes';
import Popup from 'reactjs-popup';

function CreateCodes() {
	const [allTypes, setAllTypes] = useState([]);
	const [allCodes, setAllCodes] = useState([]);
	const [showCodes, setShowCodes] = useState(false);
	const [codeCreated, setCodeCreated] = useState('');
	const [type, setType] = useState('');
	const [name, setName] = useState('');
	const [error, setError] = useState('');
	const [showConfirmation, setConfirmation] = useState(false);
	const [codeRemove, setCodeRemove] = useState({});

	const getAllTypes = async () => {
		await get('types')
			.then(res => {
				setAllTypes(res);
				return res;
			})
			.catch(err => {
				console.log(err);
			});
	};

	const getAllCodes = async () => {
		await get('codes')
			.then(res => {
				setAllCodes(res);
				return res;
			})
			.catch(err => {
				return { err: true, msg: err.message };
			});
	};

	useEffect(() => {
		getAllTypes();
		getAllCodes();
	}, []);

	useEffect(() => {
		getAllCodes();
	}, [codeCreated, codeRemove]);

	const addType = () => {
		console.log({ type });
		if (!type || !name) {
			setError('Campos obrigatórios');
			return;
		}

		setCode({ type, name }).then(code => {
			setCodeCreated(code.code);
		});

		setError('');
		setName('');
		setType('none');

		document.getElementById('inputCreateCode').focus();
	};

	const openModal = () => {
		setConfirmation(!showConfirmation);
	};

	const closeModal = () => {
		setConfirmation(false);
	};

	const removeCode = () => {
		removeOneCode(codeRemove);
		setCodeRemove({});
		setShowCodes(false);
		closeModal();
	};

	return (
		<div>
			<Popup open={showConfirmation} onClose={closeModal}>
				<h2>
					Tem certeza que deseja excluir {codeRemove.type}-
					{codeRemove.code}?
				</h2>
				<div style={{ display: 'flex', gap: '15px' }}>
					<button
						style={{
							width: '70px',
							padding: '10px',
							backgroundColor: 'lightgrey',
							cursor: 'pointer',
						}}
						onClick={removeCode}
					>
						Sim
					</button>
					<button
						style={{
							width: '70px',
							padding: '10px',
							cursor: 'pointer',
						}}
						onClick={openModal}
					>
						Não
					</button>
				</div>
			</Popup>
			<div>
				<h2>Registro Códigos</h2>
				{error && <span style={{ color: 'red' }}>{error}</span>}
			</div>
			<div className='containerInputs'>
				<select
					name='select'
					value={type}
					onChange={e => setType(e.target.value)}
				>
					<option value={'none'}>nenhum</option>
					{allTypes &&
						allTypes.map(type => (
							<option value={type.name}>{type.name}</option>
						))}
				</select>
				<input
					id='inputCreateCode'
					type='text'
					value={name}
					onChange={e => setName(e.target.value)}
					placeholder='Nome'
				/>

				<button onClick={() => addType()}>Cadastrar</button>
				<button onClick={() => setShowCodes(!showCodes)}>
					{showCodes ? 'Esconder Códigos' : 'Mostrar Códigos'}
				</button>
				{codeCreated && (
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							fontWeight: 'bold',
							color: 'green',
						}}
					>
						{codeCreated}
					</span>
				)}
			</div>
			{showCodes && allCodes && (
				<div className='list'>
					<table>
						<tr>
							<th style={{ width: '20%', textAlign: 'left' }}>
								Codigo
							</th>
							<th style={{ width: '60%', textAlign: 'left' }}>
								Name
							</th>
							<th style={{ textAlign: 'left' }}>Tipo</th>
						</tr>
						{allCodes.map((code, index) => (
							<tr
								style={{
									backgroundColor:
										index % 2 === 0 && '#FBFBFB',
									height: '40px',
								}}
							>
								<td>{code.code}</td>
								<td>{code.name}</td>
								<td>{code.type}</td>
								<td
									style={{ color: 'red', cursor: 'pointer' }}
									onClick={() => {
										setCodeRemove({
											type: code.type,
											code: code.code,
										});
										openModal();
									}}
								>
									X
								</td>
							</tr>
						))}
					</table>
				</div>
			)}
		</div>
	);
}

export default CreateCodes;
