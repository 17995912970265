import "./Geral.css";
import "./Components.css";
import React, { useEffect, useState } from "react";
import { setType } from "../api/types";
import { get } from "../api/firebase";

function CreateTypes() {
  const [allTypes, setAllTypes] = useState([]);
  const [showTypes, setShowTypes] = useState(false);
  const [codeMP, setCodeMP] = useState("");
  const [nameMP, setNameMP] = useState("");
  const [errorMP, setErrorMP] = useState("");

  const getAllTypes = async () => {
    await get("types")
      .then((res) => {
        setAllTypes(res);
        return res;
      })
      .catch((err) => {
        return { err: true, msg: err.message };
      });
  };

  useEffect(() => {
    getAllTypes();
  }, []);

  const addType = () => {
    if (!codeMP || !nameMP) {
      setErrorMP("Campos obrigatórios");
      return;
    }

    setErrorMP("");
    setCodeMP("");
    setNameMP("");

    document.getElementById("inputCreateMP").focus();

    setType({
      code: codeMP.toUpperCase(),
      name: nameMP.charAt(0).toUpperCase() + nameMP.slice(1),
    });

    getAllTypes();
  };

  return (
    <div>
      <div>
        <h2>Registro Tipos de matéria prima</h2>
        {errorMP && <span style={{ color: "red" }}>{errorMP}</span>}
      </div>
      <div className="containerInputs">
        <input
          id="inputCreateMP"
          type="text"
          value={nameMP}
          onChange={(e) => setNameMP(e.target.value)}
          placeholder="Nome"
        />
        <input
          type="text"
          value={codeMP}
          onChange={(e) => setCodeMP(e.target.value)}
          placeholder="Código"
        />
        <button onClick={() => addType()}>Cadastrar</button>
        <button onClick={() => setShowTypes(!showTypes)}>
          {showTypes ? "Esconder tipos" : "Mostrar tipos"}
        </button>
      </div>
      {showTypes && allTypes && (
        <div className="list">
          <table>
            <tr>
              <th style={{ width: "30%", textAlign: "left" }}>Codigo</th>
              <th style={{ textAlign: "left" }}>Name</th>
            </tr>
            {allTypes.map((type) => (
              <tr>
                <td>{type.code}</td>
                <td>{type.name}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </div>
  );
}

export default CreateTypes;
