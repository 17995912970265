import { set } from './firebase';

const DATABASE = 'types';

export const setType = data => {
	data = { ...data, Quantity: 0 };
	set(DATABASE, data)
		.then(res => {
			return res;
		})
		.catch(err => {
			console.log({ err });
		});
};
